<form [formGroup]="registerForm" (ngSubmit)="register(formDirective)" #formDirective="ngForm">
    <section>
        <mat-form-field>
          <mat-label for="name">Name</mat-label>
          <input matInput id="name" formControlName="name" />
        </mat-form-field>
      </section>
    <section>
      <mat-form-field>
        <mat-label for="email">Email</mat-label>
        <input matInput id="email" formControlName="email" />
      </mat-form-field>
    </section>
    <section>
      <mat-form-field>
        <mat-label for="password">Password</mat-label>
        <input
          matInput
          type="password"
          id="password"
          formControlName="password"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label for="confirm-password">Confirm Password</mat-label>
        <input
          matInput
          type="password"
          id="confirm-password"
          formControlName="confirmPassword"
        />
      </mat-form-field>
      @if (formDirective.errors?.['matchValue']) {
        <mat-error>Passwords must match</mat-error>
      }
    </section>
    <button
      mat-flat-button
      color="primary"
      type="submit"
      [disabled]="registerForm.invalid || disabled"
    >
      Register
    </button>
  </form>
