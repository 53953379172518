<main class="main" #main>
  <div class="header">
    <app-subscription-banner
      (clicked)="scrollToAction()"
    ></app-subscription-banner>
  </div>
  <div class="content">
    <div class="left-side">
      <h1>
        <a routerLink="/">{{ title }}</a>
        <button mat-icon-button [matMenuTriggerFor]="appMenu" aria="menu">
          <mat-icon>menu</mat-icon>
        </button>
      </h1>
      <mat-menu #appMenu="matMenu">
        <a mat-menu-item routerLink="/"><mat-icon>home</mat-icon> Home</a>
        @if (loggedIn) {
          <a mat-menu-item routerLink="/profile"
            ><mat-icon>account_circle</mat-icon> Profile</a
          >
          <a mat-menu-item routerLink="/subscription"
            ><mat-icon>credit_card</mat-icon> Subscription</a
          >
          <button mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>Logout
          </button>
        }
      </mat-menu>
      @if (loggedIn) {
        <app-whatsapp-button [user]="user" class="show-on-mobile" />
        <app-chat [user]="userName" />
      } @else {
        <mat-tab-group>
          <mat-tab label="Register">
            <app-registerform></app-registerform>
          </mat-tab>
          <mat-tab label="Login">
            <app-loginform></app-loginform>
          </mat-tab>
        </mat-tab-group>
        <app-facebook-login [loggedIn]="loggedIn"></app-facebook-login>
      }
    </div>
    <div class="divider" role="separator" aria-label="Divider"></div>
    <div class="right-side" #actions>
      <router-outlet />
    </div>
  </div>
  <div class="footer">
    <p>v{{ version }}</p>
  </div>
</main>
