import { Component, EventEmitter, inject, Output } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { PaypalButtonComponent } from '../paypal-button/paypal-button.component';
import { RouterLink } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { CommonModule } from '@angular/common';
import { SubscriptionService } from '../../services/subscription.service';
import { map } from 'rxjs';

@Component({
  selector: 'app-subscription-banner',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, MatButtonModule, PaypalButtonComponent, MatButtonModule, RouterLink],
  templateUrl: './subscription-banner.component.html',
  styleUrl: './subscription-banner.component.scss'
})
export class SubscriptionBannerComponent {
  private readonly subscriptionService = inject(SubscriptionService);
  public readonly loginService = inject(LoginService);

  notSubscribed$ = this.subscriptionService.activeSubscription$.pipe(map((status) => !status));

  @Output() clicked = new EventEmitter<void>();

  trigger(): void {
    this.clicked.emit();
  }
}
