<mat-card class="chat-box" #chat>
  @for (message of messages; track message.uuid) {
    <p class="chat-bubble" #chatText>{{ message.sender }}: {{ message.text }}</p>
  }
</mat-card>
@if (connectionError) {
  <div class="connection-error">Connection lost, please refresh the page.</div>
} @else {
<mat-form-field class="message-input">
  <input matInput id="message" type="text" [placeholder]="reconnecting ? 'Reconnecting, please wait...' : 'Type a message...'" (keydown.enter)="sendMessage()" [(ngModel)]="messageInput" [disabled]="reconnecting || connectionError"/>
    
  <button
  matSuffix
  mat-icon-button
  aria-label="send"
  [disabled]="!messageInput || reconnecting || connectionError"
  (click)="sendMessage()"
  >
  @if (reconnecting) {
    <mat-spinner [diameter]="20"></mat-spinner>
  } @else {
    <mat-icon>send</mat-icon>
  }
  </button>
</mat-form-field>
}
  
