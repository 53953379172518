import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { PaypalButtonComponent } from '../paypal-button/paypal-button.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SubscriptionPlan } from '../../models/subscriptionplan.model';
import { SubscriptionService } from '../../services/subscription.service';

@Component({
  selector: 'app-subscription-panel',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, PaypalButtonComponent],
  templateUrl: './subscription-panel.component.html',
  styleUrl: './subscription-panel.component.scss',
})
export class SubscriptionPanelComponent implements OnInit, OnDestroy {
  paypalClientId = environment.paypalClientId;

  subscriptionPlans = new Array<SubscriptionPlan>();

  subscriptionIsActive = false;
  unsubscribe$ = new Subject<void>();

  constructor(
    private subscriptionService: SubscriptionService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.subscriptionService.activeSubscription$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isActive) => {
        this.subscriptionIsActive = isActive;
      });

    this.subscriptionService
      .getSubscriptionPlans()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plans) => {
        this.subscriptionPlans = plans;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  cancelSubscription(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Cancel Subscription',
        description: 'Do you want to cancel your current subscription?',
        buttons: {
          cancelTitle: 'No',
          confirmTitle: 'Yes',
        },
        buttonColor: 'warn',
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.subscriptionService.cancelSubscription().subscribe(() => {
          this.subscriptionService.checkSubscriptionStatus();
        });
      }
    });
  }
}
