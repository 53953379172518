export class User {
  public phone_number: string | null = null;

  constructor(
    public uuid: string | null,
    public name: string,
    public email: string,
    public password: string | null = null,
    public phoneNumber: string | null = null,
  ) {}
}
