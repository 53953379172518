{
  "name": "taskmaster",
  "version": "0.2.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint",
    "prettier": "pretty-quick --pattern \"taskmaster/src/**/*.{ts,js,scss,html}\" --staged --verbose"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.0",
    "@angular/cdk": "^18.0.0",
    "@angular/common": "^18.0.0",
    "@angular/compiler": "^18.0.0",
    "@angular/core": "^18.0.0",
    "@angular/forms": "^18.0.0",
    "@angular/material": "^18.0.0",
    "@angular/platform-browser": "^18.0.0",
    "@angular/platform-browser-dynamic": "^18.0.0",
    "@angular/router": "^18.0.0",
    "@auth0/angular-jwt": "^5.2.0",
    "@paypal/paypal-js": "^8.1.0",
    "@types/facebook-js-sdk": "^3.3.11",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.1",
    "@angular/cli": "^18.0.1",
    "@angular/compiler-cli": "^18.0.0",
    "@types/jasmine": "~5.1.0",
    "angular-eslint": "18.0.1",
    "eslint": "^9.3.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "pretty-quick": "^4.0.0",
    "typescript": "~5.4.2",
    "typescript-eslint": "8.0.0-alpha.20"
  },
  "overrides": {
    "rollup": "npm:@rollup/wasm-node"
  }
}
