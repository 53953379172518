<mat-dialog-content>
  <div class="container">
    <h1 class="dialog-title">{{ dialog.title }}</h1>
    <p class="dialog-description">{{ dialog.description }}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button class="close-btn" [mat-dialog-close]="false">{{ dialog.buttons.cancelTitle }}</button>
  <button mat-stroked-button class="save-btn" [mat-dialog-close]="true" [color]="dialog.buttonColor">{{ dialog.buttons.confirmTitle }}</button>
</mat-dialog-actions>
