import { Injectable } from "@angular/core";
import { loadScript, PayPalNamespace } from "@paypal/paypal-js";
import { environment } from "../../environments/environment";
import { BehaviorSubject } from "rxjs";


@Injectable({
  providedIn: 'root',
})
export class PaypalService {
  initalized$ = new BehaviorSubject<boolean>(false);

  paypal: PayPalNamespace | null = null;

  constructor() {
    loadScript({
      "clientId": environment.paypalClientId,
      "vault": true,
      "intent": "subscription",
    }).then((paypal) => {
      this.paypal = paypal
      this.initalized$.next(true);
    }).catch((err) => {
      console.error('failed to load the PayPal JS SDK script', err);
    });
  }
}
