import { Component, Input, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { MatButtonModule } from '@angular/material/button';
import { EMPTY, Observable, concatMap, finalize, from, of } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SnackBarService } from '../../services/snackBar.service';
import { environment } from '../../../environments/environment';

class FbResponse {
  constructor(public name: string) {}
}

@Component({
  selector: 'app-facebook-login',
  standalone: true,
  imports: [MatButtonModule, CommonModule, MatIconModule],
  templateUrl: './facebook-login.component.html',
  styleUrl: './facebook-login.component.scss',
})
export class FacebookLoginComponent implements OnInit {
  @Input() loggedIn!: boolean;
  loginUrl = environment.apiUrl + '/auth/fb';
  accessToken: string | undefined;
  buttonText: string | undefined;

  inProgress = false;
  constructor(
    private loginService: LoginService,
    private snackBarService: SnackBarService,
  ) {}

  ngOnInit(): void {
    FB.getLoginStatus(({ authResponse }) => {
      if (authResponse?.accessToken) {
        this.accessToken = authResponse.accessToken;
        FB.api('/me', { fields: 'name' }, (response: FbResponse) => {
          if (response) {
            this.buttonText = 'Continue as ' + response.name;
          }
        });
      }
    });
  }

  login() {
    this.inProgress = true;
    if (this.accessToken) {
      this.loginService
        .apiAuthenticate(this.accessToken)
        .pipe(finalize(() => (this.inProgress = false)))
        .subscribe((jwt) => {
          localStorage.setItem('jwt', jwt.access_token);
          localStorage.setItem('refresh', jwt.refresh_token);
          this.loginService.updateStatus(true);
          this.snackBarService.openSnackbar('Login Successful', 'success');
        });
    } else {
      this.facebookLogin()
        .pipe(
          concatMap((accessToken) =>
            this.loginService.apiAuthenticate(accessToken ?? ''),
          ),
          finalize(() => (this.inProgress = false)),
        )
        .subscribe((jwt) => {
          localStorage.setItem('jwt', jwt.access_token);
          localStorage.setItem('refresh', jwt.refresh_token);
          this.loginService.updateStatus(true);
          this.snackBarService.openSnackbar('Login Successful', 'success');
        });
    }
  }

  facebookLogin(): Observable<string | undefined> {
    return from(
      new Promise<fb.StatusResponse>((resolve) =>
        FB.login(resolve, { scope: 'email' }),
      ),
    ).pipe(
      concatMap(({ authResponse }) => {
        if (!authResponse) {
          this.snackBarService.openSnackbar('Login rejected', 'error');
          return EMPTY;
        }
        return of(authResponse.accessToken);
      }),
    );
  }

  logout() {
    this.loginService.logout();
  }
}
