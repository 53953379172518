import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { Message, ChatInput } from '../../models/message.model';
import { ChatService } from '../../services/chat.service';
import { Subject, takeUntil } from 'rxjs';
import { scrollToSmoothly } from '../../utils/scrollsmoothly.function';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@Component({
  selector: 'app-chat',
  standalone: true,
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
})
export class ChatComponent implements OnInit, OnDestroy {
  @Input() user = 'Guest';

  messageInput = '';
  messages: Message[] = Array<Message>();
  unsubscribe$ = new Subject<void>();
  reconnecting = false;
  connectionError = false;
  
  @ViewChild('chat', { read: ElementRef, static: false }) chat!: ElementRef;
  @ViewChildren('chatText') chatTexts!: QueryList<ElementRef>;

  constructor(private chatService: ChatService) {}

  ngOnInit(): void {
    this.chatService.connect();
    this.observeChat();
    this.chatService.reconnect$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.reconnecting = true;
      this.observeChat();
    });
    this.chatService.connectionError$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.connectionError = true);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  sendMessage(): void {
    if (this.messageInput) {
      const message = new Message(
        crypto.randomUUID(),
        this.messageInput,
        new Date(),
        this.user,
      );
      const chatInput = new ChatInput(message, this.messages);
      this.messages.push(message);
      this.chatService.send(chatInput);
      this.messageInput = '';
      this.scrollChat();
    }
  }

  private observeChat() {
    this.chatService.onMessage().pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (event: MessageEvent) => {
        if (event.data) {
          this.reconnecting = false;
          const message = JSON.parse(event.data) as Message;
          this.messages.push(message);
          this.chatService.messageReceived$.next();
          this.scrollChat();
        }
      },
      error: (error) => console.log(error)
    });
  }

  private scrollChat(): void {
    setTimeout(() => {
      const texts = this.chatTexts;
      const lastText = texts.last;
      if (this.chat && lastText) {
        scrollToSmoothly(this.chat, lastText, 350);
      }
    }, 200);
  }
}
