import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';
import { LoginService } from '../../services/login.service';
import { SnackBarService } from '../../services/snackBar.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private snackBarService: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      const fbToken = queryParams['fb_token'];

      if (fbToken) {
        this.loginService
          .apiAuthenticate(fbToken)
          .pipe(finalize(() => this.router.navigate(['/'])))
          .subscribe((jwt) => {
            localStorage.setItem('jwt', jwt.access_token);
            localStorage.setItem('refresh', jwt.refresh_token);
            this.loginService.updateStatus(true);
            this.snackBarService.openSnackbar('Login Successful', 'success');
          });
      } else {
        this.router.navigate(['/']);
      }
    });
  }
}
