import { ElementRef } from '@angular/core';

export function scrollToSmoothly(elementToScroll: ElementRef, child: ElementRef, duration: number) {
  const currentPos = elementToScroll.nativeElement.scrollTop;
  const endPos = child.nativeElement.offsetTop;
  const startTime = performance.now();

  function frame() {
    const elapsedTime = performance.now() - startTime;
    const progress = Math.min(1, elapsedTime / duration);
    if (currentPos < endPos) {
      elementToScroll.nativeElement.scrollTo(0, (endPos - currentPos) * progress + currentPos);
    } else {
      elementToScroll.nativeElement.scrollTo(0, currentPos - (currentPos - endPos) * progress);
    }
    if (progress < 1) {
      requestAnimationFrame(frame);
    } else {
      elementToScroll.nativeElement.scrollTo(0, endPos);
    }
  }

  requestAnimationFrame(frame);
}