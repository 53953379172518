import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Subscription } from '../models/subscription.model';
import { SubscriptionPlan } from '../models/subscriptionplan.model';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  activeSubscription$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  checkSubscriptionStatus() {
    this.getSubscriptionStatus().subscribe((subscription) => {
      this.activeSubscription$.next(subscription.isActive);
    });
  }

  getSubscriptionStatus(): Observable<Subscription> {
    return this.http.get<Subscription>(`${environment.apiUrl}/subscriptions/status`);
  }

  linkSubscription(subscriptionId: string): Observable<Subscription> {
    return this.http.post<Subscription>(`${environment.apiUrl}/subscriptions/link`, { subscriptionId });
  }

  cancelSubscription(): Observable<Subscription> {
    return this.http.post<Subscription>(`${environment.apiUrl}/subscriptions/cancel`, {});
  }

  getSubscriptionPlans(): Observable<SubscriptionPlan[]> {
    return this.http.get<SubscriptionPlan[]>(`${environment.apiUrl}/subscriptions/plans`);
  }
 }
