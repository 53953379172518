import { Component, Input, OnChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { User } from '../../models/user.model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-whatsapp-button',
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
  templateUrl: './whatsapp-button.component.html',
  styleUrl: './whatsapp-button.component.scss',
})
export class WhatsappButtonComponent implements OnChanges {
  @Input() user: User | null = null;

  baseLink = `https://wa.me/${environment.whatsappNumber}`;
  messageLink = `${this.baseLink}?text=Hello`;

  ngOnChanges(): void {
    if (!this.user?.phoneNumber && this.user?.email) {
      this.messageLink = `${this.baseLink}?text=Link my account with email ${this.user.email}`;
    }
  }
}
