<mat-card class="profile">
  <mat-card-header>
    <mat-card-title>Profile</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>Name: {{ user?.name }}</p>
    <p>Email: {{ user?.email }}</p>
  </mat-card-content>
  <mat-card-actions align="end">
    <a mat-button href="/privacy.html" target="_blank">Privacy Policy</a>
    @if (user) {
      <button mat-button color="warn" (click)="deleteAccount()">Delete Account</button>
    }
  </mat-card-actions>
</mat-card>
