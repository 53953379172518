import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { User } from '../models/user.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getCurrentUser(): Observable<User> {
    return this.http
      .get<User>(environment.apiUrl + '/users/me')
      .pipe(
        map(
          (user: User) =>
            new User(user.uuid, user.name, user.email, null, user.phone_number),
        ),
      );
  }

  deleteCurrentUser(): Observable<void> {
    return this.http.delete<void>(environment.apiUrl + '/users/me');
  }

  registerUser(user: User): Observable<User> {
    return this.http.post<User>(environment.apiUrl + '/users', user);
  }
}
