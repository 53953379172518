import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { Subject, takeUntil } from 'rxjs';
import { LoginformComponent } from './components/loginform/loginform.component';
import { RegisterformComponent } from './components/registerform/registerform.component';
import { FacebookLoginComponent } from './components/facebook-login/facebook-login.component';
import { SubscriptionBannerComponent } from './components/subscription-banner/subscription-banner.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { ChatComponent } from './components/chat/chat.component';
import { WhatsappButtonComponent } from './components/whatsapp-button/whatsapp-button.component';
import { User } from './models/user.model';
import { LoginService } from './services/login.service';
import { UserService } from './services/user.service';
import { SubscriptionService } from './services/subscription.service';
import { version } from '../../package.json';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTabsModule,
    LoginformComponent,
    RegisterformComponent,
    FacebookLoginComponent,
    SubscriptionBannerComponent,
    TasksComponent,
    ChatComponent,
    WhatsappButtonComponent,
    SubscriptionBannerComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  public version = version;

  title = 'Help It Done!';
  user: User | null = null;
  userName = 'Guest';
  loggedIn = false;
  unsubscribe$ = new Subject<void>();

  @ViewChild('main', { read: ElementRef, static: false }) main!: ElementRef;
  @ViewChild('actions', { read: ElementRef, static: false })
  actions!: ElementRef;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private userService: UserService,
    private subscriptionService: SubscriptionService,
  ) {}

  ngOnInit(): void {
    this.loginService.loginStatus$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((status) => {
        this.loggedIn = status;
        this.loadUserInfo();
      });
    const storedToken = localStorage.getItem('jwt');
    if (storedToken) {
      this.loginService.updateStatus(true);
    } else {
      this.loadUserInfo();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadUserInfo() {
    if (!this.loggedIn) {
      this.user = null;
      this.userName = 'Guest';
    } else {
      this.userService.getCurrentUser().subscribe((user) => {
        this.user = user;
        this.userName = user.name ?? 'Anon';
      });
    }
  }

  logout() {
    this.loginService.logout();
    this.router.navigate(['/']);
  }

  scrollToAction(): void {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 200);
  }
}
