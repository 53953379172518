@if (subscriptionIsActive) {
  <p>Thanks for subscribing</p>
  <button mat-flat-button color="warn" (click)="cancelSubscription()">Cancel Subscription</button>
}
@else {
  @for (plan of subscriptionPlans; track plan.id_) {
    @if (plan.status === "ACTIVE") {
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{ plan.name }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p class="card-text">{{ plan.description }}</p>
        </mat-card-content>
        <mat-card-actions>
          <app-paypal-button
            [clientId]="paypalClientId"
            [planId]="plan.id_"
          />
        </mat-card-actions>
      </mat-card>
    }
  }
}
