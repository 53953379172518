import { Component, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { User } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import { PaypalService } from '../../services/paypal.service';
import { SubscriptionService } from '../../services/subscription.service';


@Component({
  selector: 'app-paypal-button',
  standalone: true,
  imports: [],
  templateUrl: './paypal-button.component.html',
  styleUrl: './paypal-button.component.scss'
})
export class PaypalButtonComponent {
  @Input() clientId = '';
  @Input() planId = '';
  @Input() layout: 'vertical' | 'horizontal' = 'vertical';

  user: User | undefined | null;

  constructor(
    private userService: UserService,
    private paypalService: PaypalService,
    private subscriptionService: SubscriptionService,
  ) {
    this.userService.getCurrentUser().subscribe(user => {
      this.user = user;
    });

    this.paypalService.initalized$.pipe(takeUntilDestroyed()).subscribe((initalized) => {
      if(initalized) {
        this.renderPaypalButton();
      }
    });
  }

  renderPaypalButton(): void {
    const planId = this.planId;
    const userId = this.user?.uuid || undefined;

    const subscriptionService = this.subscriptionService;
    const paypal = this.paypalService.paypal;

    if(paypal?.Buttons) {
      paypal.Buttons({
        style: {
          layout: this.layout,
        },
        createSubscription: function(data, actions) { 
          return actions.subscription.create({
            'plan_id': planId,
            'custom_id': userId,
            });
          },
        onApprove: function(data) {
          if (data.subscriptionID) {
            subscriptionService.linkSubscription(data.subscriptionID).subscribe(() => {
              // Forcing subscription to be active to avoid waiting too much for webhook
              subscriptionService.activeSubscription$.next(true);
            });
          }
          return Promise.resolve();
        },
        }).render('#paypal-button-container-' + planId);
    }
  }
}
