@if (!loggedIn) {
  <a
    mat-flat-button
    class="login-btn show-on-mobile"
    style="background-color: #1877f2"
    [href]="loginUrl"
  >
    <mat-icon>facebook</mat-icon>
    Register with Facebook
  </a>
  <button
    mat-flat-button
    class="login-btn hide-on-mobile"
    style="background-color: #1877f2"
    type="submit"
    [disabled]="inProgress"
    (click)="login()"
  >
    <mat-icon>facebook</mat-icon>
    {{ buttonText ?? "Register with Facebook" }}
  </button>
} @else {
  <button
    mat-stroked-button
    class="logout-btn"
    color="secondary"
    type="submit"
    (click)="logout()"
  >
    Logout
  </button>
}
