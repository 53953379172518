@if ((loginService.loginStatus$ | async) && (notSubscribed$ | async)) {
  <div class="subscription-banner">
    <span>Limited time offer</span>
    <h3>Founder's deal</h3>
    <p>
      Subscribe now to secure a lifetime price of <b>$2.99</b> monthly&nbsp;
    </p>
    <button mat-flat-button routerLink="/subscription" (click)="trigger()">
      Subscribe now!
    </button>
  </div>
}
