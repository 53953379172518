import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private socket: Subject<MessageEvent>;
  public messageReceived$ = new Subject<void>();
  socketClosed = false;
  reconnect$ = new Subject<void>();
  connectionError$ = new Subject<void>();
  webSocketRetryAttempts = 0;

  constructor() {
    this.socket = new Subject();
  }
  
  public connect(): void {
    const token = localStorage.getItem('jwt');
    const ws = new WebSocket(environment.apiUrl + '/chat/' + token);

    this.socket.subscribe({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      next: (data: any) => {
        if(data && ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      },
      error: (err: ErrorEvent) => {
        if (this.webSocketRetryAttempts < 4) {
          this.webSocketRetryAttempts++;
          ws.close();
          setTimeout(() => {
            this.reconnect();
          }, this.webSocketRetryAttempts * 5000);
        } else {
          this.connectionError$.next();
          console.error('WebSocket error:', err);
        }
      },
      complete: () => ws.close()
    });


    ws.onmessage = (event) => {
      this.webSocketRetryAttempts = 0;
      this.socket.next(event);
    };
    ws.onerror = (event) => this.socket.error(event);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ws.onclose = (_event) => {
      this.socketClosed = true;
      this.socket.complete();
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public send(data: any): void {
    if (data) {
      if (this.socketClosed) {
        this.reconnect();
      }
      this.socket.next(data);
    }
  }

  public onMessage(): Observable<MessageEvent> {
    return this.socket.asObservable();
  }

  private reconnect(): void {
    this.socket = new Subject();
    this.connect();
    this.socketClosed = false;
    this.reconnect$.next();
  }
}
